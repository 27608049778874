.ProductBtn {
  outline: none !important;
  width: 100%;
  max-width: 400px;
  margin: 0 auto 22px;
  background: $grey-4;
  border: 1px solid $grey-3;
  box-sizing: border-box;
  border-radius: 4px;
  height: 64px;
  display: flex;
  align-items: center;
  padding: 0;
  overflow: hidden;
  .bullet {
    height: 62px;
    width: 62px;
    border-right: solid 1px $grey-3;
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    span {
      width: 20px;
      height: 20px;
      border-radius: 10px;
      border: solid 2px $blue;
    }
  }
  .label {
    padding: 0 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    p {
      margin: 0;
      &.local {
        color: $grey-1;
        font-weight: 500;
        font-size: 20px;
        line-height: 23px;
        letter-spacing: 0.03em;
      }
      &.crypto {
        color: $secondary;
        font-size: 18px;
        line-height: 21px;
        letter-spacing: 0.03em;
      }
    }
  }
  &.active {
    border: 1px solid $blue;
    .bullet {
      border-right: solid 1px $blue;
      span {
        display: flex;
        align-items: center;
        justify-content: center;
        &:after {
          content: '';
          width: 10px;
          height: 10px;
          background: $blue;
          border-radius: 5px;
        }
      }
    }
  }
}