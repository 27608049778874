.home-logo {
  width: 320px;
  max-width: 100%;
  margin: 0 auto !important;
}

.home-illustration--01 {
  background-repeat: no-repeat;
  background-position: center;
  padding-top: 240px;
  background-size: auto 100%;
  margin-bottom: 28px;
  @media (min-width: 768px) {
    margin-bottom: 64px;
    padding-top: 400px;
  }
  &--venezuela {
    background-image: url('/illustrations/home_01_venezuela.svg');
  }
  &--cuba {
    background-image: url('/illustrations/home_01_cuba.svg');
  }
  &--index {
    background-image: url('/illustrations/index_01.svg');
  }
}

.home-intro-text {
  width: 600px;
  max-width: 100%;
  margin: 0 auto;
  text-align: center;
  p.bigger {
    font-size: 1.5em;
  }
}

.countrylink {
  text-align: center;
  text-decoration: none;
  font-size: 24px;
  color: #47466C;
  display: inline-block;
  p {
    margin-top: 0em;
  }
}

.home-foot {
  margin-top: 2em;
  text-align: center;
}

.home-securecharge, .home-comodity {
  color: $grey-1;
  h2 {
    margin-bottom: 20px;
    font-weight: 400;
    font-size: 24px;
    color: $black;
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      padding: 0;
      display: flex;
      align-items: flex-start;
      font-size: 20px;
      line-height: 26px;
      padding-bottom: 10px;
      svg {
        margin-top: 4px;
        margin-right: 16px;
        path {
          fill: $success;
        }
      }
    }
  }
  img {
    max-width: 100%;
    margin: 0 auto;
    display: block;
  }

  @media (min-width: 425px) {
    h2 {
      text-align: center;
    }
    ul {
      margin: 0 auto;
      max-width: 400px;
    }
  }
  @media (min-width: 960px) {
    h2 {
      text-align: left;
    }
    ul {
      margin: 0;
      max-width: initial;
    }
  }
}

.home-intro-text-wrapper {
  &>div {
    background: #534b99;
  }
    p {
      margin: 0;
      color: white;
    }
  &:before, &:after {
    background-image: url('/illustrations/home-comodity-skirt--index.svg');
  }
  &:after {
    background-image: url('/illustrations/home-comodity-skirt-bottom--index.svg');
  }
  &:before, &:after {
    content: '';
    height: 140px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center top;
    display: block;
  }
}

.home-intro-footer {
  color: white;
  padding: 33px 16px;
  margin-top: 44px;
  justify-content: center;

      background-image: url('/illustrations/home-bottom-cta-bg--index.svg');
    
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center top;
    padding: 72px 0 60px; 
    margin-top: 84px;
    a {
      color: white;
    }
}

.home-comodity {
  &--venezuela {
    .home-comodity-wrapper {
      background: unquote(map-get($venezuela, 'seconday-light'));
    }
    &:before, &:after {
      background-image: url('/illustrations/home-comodity-skirt--venezuela.svg');
    }
    &:after {
      background-image: url('/illustrations/home-comodity-skirt-bottom--venezuela.svg');
    }
  }
  &--cuba {
    .home-comodity-wrapper {
      background: unquote(map-get($cuba, 'seconday-light'));
    }
    &:before, &:after {
      background-image: url('/illustrations/home-comodity-skirt--cuba.svg');
    }
    &:after {
      background-image: url('/illustrations/home-comodity-skirt-bottom--cuba.svg');
    }
  }
  &:before, &:after {
    content: '';
    height: 140px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center top;
    display: block;
  }
}

.home-info {
  h2 {
    margin-top: 0;
    margin-bottom: 46px;
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
    color: $black;
    @media (min-width: 960px) {
      margin-bottom: 20px;
    }
  }
  .MuiGrid-item {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    @media (min-width: 960px) {
      padding: 24px !important;
    }
  }
  .question, .answer {
    font-size: 18px;
    line-height: 24px;
    color: $grey-1;
  }
  .question {
    margin-top: 0;
  }
  .answer {
    margin-bottom: 20px;
    a {
      color: $blue;
      text-decoration: none;
    }
  }
  
  &--venezuela {
    .home-info-wrapper {
      background: unquote(map-get($venezuela, 'seconday-light'));
    }
    &:before, &:after {
      background-image: url('/illustrations/home-comodity-skirt--venezuela.svg');
    }
    &:after {
      background-image: url('/illustrations/home-comodity-skirt-bottom--venezuela.svg');
    }
  }
  &--cuba {
    .home-info-wrapper {
      background: unquote(map-get($cuba, 'seconday-light'));
    }
    &:before, &:after {
      background-image: url('/illustrations/home-comodity-skirt--cuba.svg');
    }
    &:after {
      background-image: url('/illustrations/home-comodity-skirt-bottom--cuba.svg');
    }
  }
  &:before, &:after {
    content: '';
    height: 140px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center top;
    display: block;
  }
}

.static-page .terms {
  max-width: 800px;
  margin: 0 auto;
  h1 {
    text-align: center;
    font-size: 24px;
  }
  h2 {
    font-size: 18px;
    margin-top: 3em;
  }
  p, ul {
    font-size: 14px;
  }
}

.legals-links {
  padding: 60px 0 30px;
  font-size: 14px;
  text-align: center;
  a {
    color: black;
    margin: 0 8px 16px;
  }
}

.check_terms {
  text-align: center;
  padding: 20px 0;
  font-size: 14px;
  input {
    margin-right: 8px;
  }
}