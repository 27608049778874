.EmailFields {
  padding: 32px 0 20px;
  h3 {
    font-weight: bold;
    font-size: 26px;
    line-height: 30px;
    color: $secondary;
    margin: 0 0 10px;
  }
  p {
    font-size: 16px;
    line-height: 19px;
    color: $grey-1;
    margin: 0 0 20px;
  }
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  .MuiInputBase-root {
    width: 100%;
    background: white;
    margin-bottom: 20px;
    input {
      background: transparent;
    }
    &.warning {
      border-radius: 4px;
      svg.check {
        display: none;
      }
      .email-validator {
        opacity: 1;
        svg {
          fill: #EA2B2B;
        }
      }
    }
  }
  .MuiInputAdornment-root {
    background: white;
    height: 56px !important;
    padding: 0;
    margin: 0;
    max-height: initial !important;
  }

  .warning-text {
    margin-top: -5px;
    font-size: 16px;
    line-height: 19px;
    color: #EA2B2B;
    display: none;
    &.warning {
      display: block;
    }
  }
  .email-validator {
    opacity: 0;
    &.validated {
      opacity: 1;
      svg {
        fill: $success;
      }
      svg.warning {
        display: none;
      }
    }
  }
}
