.TopBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0;
  @media (min-width: 768px) {
    padding: 68px 0 36px;
  }
  &__action, &__modal-trigger {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    color: $secondary;
    cursor: pointer;
    svg {
      width: 20px;
      height: 20px;
      fill: $secondary;
    }
    span {
      display: none;
      @media (min-width: 768px) {
        margin: 0 12px;
        display: block;
        width: 48px;
      }
    }
  }
  .TopBar__modal-trigger {
    span {
      text-align: right;
    }
  }
  .TopBar__action--check {
    svg {
      fill: $success;
    }
  }
  &__text {
    font-weight: bold;
    font-size: 26px;
    line-height: 30px;
    color: $secondary;
    flex-grow: 1;
    text-align: left;
    margin-left: 16px;
    @media (min-width: 768px) {
      text-align: center;
      margin-left: 0;
      font-size: 36px;
      line-height: 42px;
    }
  }
}

.TopBarModal {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: all .3s ease-in-out;
  pointer-events: none;
  z-index: 1000;
  &.show {
    opacity: 1;
    pointer-events: all;
  }
  .TopBarModalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(0,0,0,.5);
    z-index: 0;
  }
  .TopBarModalContent {
    overflow: scroll;
    position: fixed;
    z-index: 1;
    max-height: calc(100% - 70px);
    width: 90%;
    max-width: 812px;
    margin: 20px 0 0;
    left: 50%;
    transform: translateX(-50%);
    background: $white;
    border-radius: 4px;
    padding: 20px;
    font-size: 20px;
    line-height: 30px;
    color: $grey-1;
    h2 {
      margin: 0;
      font-weight: bold;
      font-size: 26px;
      line-height: 30px;
      color: $secondary;
    }
    .question {
      font-weight: bold;
      margin: 30px 0 12px;
    }
    .answer {
      margin: 0 0 12px;
    }
  }
  .TopBarModalClose {
    height: 50px;
    position: fixed;
    z-index: 1;
    left: 0;
    width: 100%;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      fill: $white;
    }
  }
}