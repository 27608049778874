.transaction-review {

  min-height: calc(100vh - 62px);
  h2 {
    font-weight: bold;
    font-size: 20px;
    line-height: 26px;
    padding: 4px 0;
    font-feature-settings: 'kern' off;
    color: $grey-2;
    border-bottom: solid 1px $secondary;
    margin-top: 40px;
    margin-bottom: 30px;
  }
  .transaction-review-info {
    width: 100%;
    max-width: 360px;
    margin: 0 auto;
  }
  .separator {
    position: absolute;
    border-left: solid 1px $secondary;
    top: 0;
    left: 20px;
    height: 100%;
    z-index: -1;
  }
  .review-row {
    position: relative;
    display: flex;
    align-items: flex-start;
    min-height: 120px;
    margin-bottom: 8px;
    .img {
      width: 68px;
      margin-right: 20px;
      flex-shrink: 0;
      background: white;
    }
    .info {
      p {
        margin: 0;
        font-size: 18px;
        line-height: 24px;
        color: $grey-3;
        &.success {
          color: $secondary;
        }
        &.title {
          margin-bottom: 10px;
          font-weight: bold;
        }
        &.description {
          color: $grey-2;
        }
      }
    }
  }
  .MuiContainer-root {
    position: relative;
    .dummy-wrapper {
      top: 0;
      position: absolute;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}