.static-page {
  .static-page-wrapper {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
  }
  .MuiGrid-container {
    padding-bottom: 52px;
  }
  h2 {
    margin: 0 0 17px;
    font-weight: bold;
    font-size: 26px;
    line-height: 30px;
    color: $secondary;
  }
  .content {
    font-size: 18px;
    line-height: 24px;
    color: $grey-1;
    h3 {
      margin: 30px 0 9px;
      font-weight: bold;
      font-size: 18px;
      line-height: 21px;
      color: $grey-1;
    }
    ul {
      list-style: none;
      margin: 30px 0 0;
      padding: 0;
      li {
        padding: 0;
        display: flex;
        align-items: flex-start;
        font-size: 20px;
        line-height: 26px;
        padding-bottom: 10px;
        svg {
          margin-top: 4px;
          margin-right: 16px;
          path {
            fill: $success;
          }
        }
      }
    }
  }
  .question {
    margin: 30px 0 8px;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    color: $grey-1;
  }
  .about-us-img {
    max-width: 100%;
    margin: 17px auto 52px;
    display: block;
  }
}

.player-wrapper {
  position: relative;
  padding-top: 56.25% /* Player ratio: 100 / (1280 / 720) */
}
 
.react-player {
  position: absolute;
  top: 0;
  left: 0;
}