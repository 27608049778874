$black: #191847;
$grey-1: #47466C;
$grey-2: #757491;
$grey-3: #AFB9C5;
$grey-4: #F5F5F5;
$white: #FFFFFF;
$blue: #2B44FF;
$secondary-blue: #1F28CF;
$alert: #EA2B2B;
$success: #0FC297;
$secondary: #69A1AC;
$secondary-2: #E3F3F5;

body {
  background-color: $white !important;
  line-height: 26px;
  color: $grey-1;
} 

.secondaryOpacityBg {
  background: rgba(227, 243, 245, 0.68);
}

$venezuela: (
  "brand": "#69A1AC",
  "seconday-light": #E3F3F5
);

$cuba: (
  "brand": "#cf142b",
  "seconday-light": #FFF7D9
);

.secondaryBg {
  padding-bottom: 60px;
  &--cuba {
    background:  unquote(map-get($cuba, 'seconday-light'));
  }
  &--venezuela {
    background:  unquote(map-get($venezuela, 'seconday-light'));
  }
}