.place-order {

  min-height: calc(100vh - 62px);
  .place-order-timeout {
    background: $success;
    color: white;
    padding: 29px 0 21px;
    margin-bottom: 34px;
    p {
      font-size: 16px;
      line-height: 19px;
      margin: 0;
      &.header {
        font-size: 20px;
        line-height: 26px;
        margin-bottom: 10px;
      }
    }
    overflow: hidden;
    max-height: 500px;
    transition: all .3s ease-in-out;
    &.hide {
      max-height: 0;
      padding: 0;
    }
  }
  .place-order-timeout-wrapper {
    width: 100%;
    max-width: 360px;
    margin: 0 auto;
    position: relative;
    svg {
      position: absolute;
      top: 0;
      right: 0;
      height: 18px;
    }
  }
}

.place-order-transaction-info {
  padding: 16px;
  border: 1px solid #AFB9C5;
  border-radius: 4px;
  margin-bottom: 20px;
  img {
    display: block;
    margin: 0 auto;
    max-width: 100%;
  }
  p {
    font-size: 16px;
    line-height: 19px;
    color: $grey-2;
    word-wrap: break-word;
  }
}

.place-order-copy-btn {

  width: 100%;
  max-width: 360px;
  height: 48px;
  font-weight: 500 !important;
  font-size: 20px !important;
  line-height: 23px !important;
}

.place-order-details {
  border: 1px solid #AFB9C5;
  border-radius: 4px;
  h3 {
    margin: 0;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    display: flex;
    align-items: center;
    letter-spacing: 0.03em;
    color: $grey-2;
  }
  h4 {
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    padding: 6px 0;
    display: flex;
    align-items: center;
    margin-top: 0;
    margin-bottom: 6px;
  }
  .MuiAccordionDetails-root {
    display: block;
  }
  .MuiIconButton-label {
    svg {
      fill: $blue;
    }
  }
  .field-value {
    margin-top: 0;
    font-size: 20px;
    line-height: 26px;
    font-feature-settings: 'kern' off;
    color: $grey-2;
  }
  hr {
    margin: 16px 0;
    border: solid .5px $secondary;
    border-bottom: none;
  }
  .order-review__amounts {
    p {
      margin: 0;
    }
    .local {
      color: $grey-1;
      font-weight: 500;
      font-size: 20px;
      line-height: 26px;
      letter-spacing: 0.03em;
    }
    .crypto {
      color: $secondary;
      font-size: 20px;
      line-height: 26px;
      letter-spacing: 0.03em;
    }
  }
}

.place-order-cta-btn-wrapper {
  padding: 40px 0 44px;
}

.place-order-cta-btn {
  width: 100%;
  max-width: 360px;
  height: 48px;
  border: solid 2px $blue !important;
  margin: 0 auto !important;
  font-weight: 500 !important;
  font-size: 20px !important;
  line-height: 23px !important;
  display: block !important;
}