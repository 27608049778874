.page-menu {
  width: 100%;
  max-width: 400px;
  margin: 32px auto 38px;
  button {
    width: 100%;
    height: 58px;
    font-size: 18px;
    font-weight: 500;
    line-height: 21px;
    border: 1px solid $grey-3;
    border-radius: 4px;
    justify-content: space-between;
    color: $grey-2;
    padding: 0 20px;
  }
  @media (min-width: 1024px) {
    display: none;
  }
}

.MuiMenu-paper {
  width: calc(100% - 48px);
  max-width: 400px !important;
  margin: 0 auto;
  a {
    text-decoration: none;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: 0.03em;
    color: $grey-2;
  }
  li {
    padding: 15px 20px;
  }
}