.TelInput {
  padding-bottom: 64px;
  @media (min-width: 768px) {
    padding-bottom: 84px;
  }
  .TelInputLabel {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    color: $grey-1;
    margin-bottom: 12px;
    @media (min-width: 768px) {
      font-weight: 500;
      font-size: 24px;
      line-height: 28px;
    }
  }
  .flag {
    width: 22px;
    margin-left: 5px;
  }
  &--home {
    .TelInputLabel { 
      font-size: 24px;
      line-height: 28px;
      color: #191847;
      font-weight: 500;
      @media (min-width: 768px) {
        text-align: center;
      }
    }
    .flag {
      display: none;
    }
  }
  &--dummy {
    input {
      font-style: italic;
      color: $grey-2 !important;
    }
  }
  .MuiFormControl-root {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
    display: block;
    padding: 5px;
    background: $blue;
    border-radius: 6px;
    .MuiOutlinedInput-adornedStart {
      width: 100%;
      height: 56px;
      padding: 0;
      overflow: hidden;
    }
    .MuiInputAdornment-positionStart {
      height: 56px;
      max-height: initial;
      background: white;
      font-weight: 400;
      font-size: 30px;
      line-height: 35px;
      padding: 0 10px 0 8px;
      border-right: 1px solid #AFB9C5;
      color: $secondary;
      margin: 0;
    }
    input {
      background: $grey-4;
      padding-left: 20px;
      font-weight: 500;
      color: $secondary;
      font-size: 30px;
      line-height: 35px;
    }
  }
  .CTABtnWrapper {
    margin-top: 20px;
  }
}