.CoinSelector {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  padding-bottom: 40px;
  @media (min-width: 768px) {
    padding-bottom: 88px;
  }
  h3 {
    margin-top: 0;
    margin-bottom: 16px;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    color: $grey-1;
  }
  .MuiFormControl-root {
    width: 100%;
  }
  .MuiSelect-root {
    display: flex;
    background: $grey-4;
    align-items: center;
    padding: 13px;
    position: relative;
    img {
      margin-right: 14px;
    }
    strong {
      margin-right: 6px;
    }
    &:after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      height: 58px;
      width: 58px;
      background: white;
      border-left: solid 1px $grey-3;
    }
  }
  svg {
    width: 17px;
    fill: $secondary-blue;
    right: 20px;
    transform: scale(2);
    top: calc(50% - 15px);
  }
}

.MuiListItem-root {
  img {
    margin-right: 14px;
  }
  strong {
    margin-right: 6px;
  }
}