.Mui-focused {
  border: none !important;
  outline: none !important;
}

.MuiContainer-root {
  padding-left: 24px !important;
  padding-right: 24px !important;
}

.footer {
  text-align: center;
  padding: 12px 0;
  nav {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }
  a {
    font-size: 12px;
    line-height: 14px;
    color: $grey-1;
    text-decoration: none;
    transition: all .3s ease-in-out;
    margin: 12px;
    white-space: nowrap;
    @media (min-width: 768px) {
      margin: 12px 20px;
    }
    &:hover {
      color: $black;
    }
  }
  &--venezuela {
    background: unquote(map-get($venezuela, 'seconday-light'));
  }
  &--cuba {
    background: unquote(map-get($cuba, 'seconday-light'));
  }
}