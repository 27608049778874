.CTABtnWrapper {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  button {
    font-size: 20px;
    width: 100%;
    font-weight: bold;
    transition: all .3s ease-in-out;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  }
  .MuiButton-contained.Mui-disabled {
    background: $blue;
    color: white;
    opacity: 0.5;
  }
}