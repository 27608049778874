
.FooterCTA {
  padding: 33px 16px;
  display: flex;
  margin-top: 44px;
  justify-content: center;
  @media (min-width: 768px) {
    &--venezuela {
      background-image: url('/illustrations/home-bottom-cta-bg--venezuela.svg');
    }
    &--cuba {
      background-image: url('/illustrations/home-bottom-cta-bg--cuba.svg');
    }
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center top;
    padding: 72px 0 60px; 
    margin-top: 84px;
  }
}