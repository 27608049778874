.BrandedHeader {
  padding: 20px 0 10px;
  @media (min-width: 1024px) {
    padding: 30px 0 20px;
  }
  .BrandedHeader__brand {
    text-align: center;
    img {
      margin-bottom: 12px;
    }
    h1 {
    margin: 0;
    font-size: 26px;
    line-height: 30px;
    color: #23292F;
    }
  }
  &.BrandedHeader {
    &--venezuela {
      h1 span {
        color: unquote(map-get($venezuela, 'brand'));
      }
    }
    &--cuba {
      h1 span {
        color: unquote(map-get($cuba, 'brand'));
      }
    }
  }
  .BrandedHeader__nav {
    display: none;
    @media (min-width: 1024px) {
      display: block;
      a {
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.03em;
        color: $grey-2;
        text-decoration: none;
        margin-left: 30px;
      }
    }
  }
  &--home {
    .BrandedHeader__nav {
      display: none;
    }
  }
  &--inner {
    @media (min-width: 1024px) {
      padding: 52px 0 80px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .BrandedHeader__brand {
        text-align: left;
        display: flex;
        align-items: center;
        img {
          margin: 0 20px 0 0;
        }
      }
    }
  }
}