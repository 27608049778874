.ReviewOrderWrapper, .order-review-wrapper {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  .info-text {
    font-size: 16px;
    line-height: 19px;
    color: rgba(25, 24, 71, 0.8);
    margin-bottom: 21px;
  }
  h4 {
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    padding: 6px 0;
    display: flex;
    align-items: center;
    margin-top: 0;
    margin-bottom: 6px;
  }
  .field-value {
    margin-top: 0;
    font-size: 20px;
    line-height: 26px;
    font-feature-settings: 'kern' off;
    color: $grey-2;
  }
  hr {
    margin: 16px 0;
    border: solid .5px $secondary;
    border-bottom: none;
  }
  .order-review__amounts {
    p {
      margin: 0;
    }
    .local {
      color: $grey-1;
      font-weight: 500;
      font-size: 20px;
      line-height: 26px;
      letter-spacing: 0.03em;
    }
    .crypto {
      color: $secondary;
      font-size: 20px;
      line-height: 26px;
      letter-spacing: 0.03em;
    }
  }
  .CTABtnWrapper {
    padding: 30px 0 60px;
  }
}

.order-review-operator {
  display: flex;
  align-items: center;
  justify-content: space-between;
  img {
    height: 90px;
    width: auto;
  }
}