
  .dummy-wrapper {
    position: relative;
    .overlay {
      position: absolute;
      top: -30px;
      left: 0;
      width: 100%;
      height: calc(100% + 30px);
      background: rgba(255,255,255,.8);
    }
    p.info-text {
      font-size: 24px;
      color: $secondary;
      font-weight: bold;
      text-align: center;
      position: absolute;
      top: 0;
      left: 0;
      padding: 30px 40px;
      width: 100%;
    }
    &.relative {
      p {
        position: static;
      }
    }
  }

  .icon-spin {
    margin: 30px 0;
    animation: icon-spin 4s infinite linear;
  }

  .icon-blink {
    animation: icon-blink 1s infinite linear;
    position: relative;
  }
  .dummy-info-icon {
    width: 80px !important;
    height: 80px !important;
  }

  @keyframes icon-spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(-359deg);
    }
  }

  @keyframes icon-blink {
    0% {
      opacity: 1;
      top: 0;
    }
    50% {
      opacity: .5;
    }
    100% {
      opacity: 1;
      top: -40px;
    }
  }

  .telinput-warning {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,.3);
    z-index: 10;
    &>div {
      width: 100%;
      max-width: 300px;
      margin: 10% auto 0;
      background: white;
      text-align: center;
      border-radius: 4px;
      p {
        font-size: 20px;
        line-height: 28px;
        padding: 24px;
      }
    }
  }

  .modalq {
    position: fixed;
    top: 0%;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,.4);
    z-index: 10;
    padding: 0 20px;
    .modal-content-wrapper {
      position: relative;
      top: 5%;
      max-height: calc(90% - 30px);
      overflow: auto;
      width: 100%;
      max-width: 600px;
      margin: 0 auto 0;
      background: white;
      padding: 20px;
      border-radius: 4px;
    }
    .closeWrapper {

      position: fixed;
      bottom: 0;
      padding: 10px;
      width: 100%;
      text-align: center;
      left: 0;
    }
    .hide-modal-btn {
      width: 30px;
      height: 30px;
      fill: white;
    }
    .overlay {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .showpopup-enter {
    opacity: 0.01;
  }

  .showpopup-enter.showpopup-enter-active {
    opacity: 1;
    transition: opacity 500ms ease-in;
  }

  .showpopup-leave {
    opacity: 1;
  }

  .showpopup-leave.showpopup-leave-active {
    opacity: 0.01;
    transition: opacity 300ms ease-in;
  }
