.OperatorSelector {
  padding-bottom: 48px;
  @media (min-width: 768px) {
    padding-bottom: 92px;
  }
  h3 {
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    width: 100%;
    max-width: 400px;
    margin: 0 auto 36px;
    color: $grey-1;
  }
  .Operators-wrapper {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    grid-gap: 10px;
    @media (min-width: 768px) {
      grid-gap: 25px;
    }
    i {
      width: 150px;
      height: 0;
      @media (min-width: 600px) {
        display: none;
      }
    }
  }
}