.OperatorBtn {
  width: 150px;
  background: white;
  border: none;
  border-radius: 4px;
  padding: 0;
  box-shadow: 1px 1px 4px 0.5px rgba(0, 0, 0, 0.09);
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  overflow: hidden;
  img {
    display: block;
    width: 100%;
  }
  span {
    display: flex;
    height: 44px;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #1F28CF;
  }
  &--dummy {
    img {
      margin: 3px;
      width: calc(100% - 6px);
      opacity: .5;
    }
    span {
      color: $grey-3;
    }
  }
  &.active {
    position: relative;
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 4px;
      border: solid 5px $blue;
    }
  }
  &:focus {
    outline: none;
  }
}