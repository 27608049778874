.ProductSelector {
  padding: 40px 0;
  @media (min-width: 768px) {
    padding: 80px 0 112px;
  }
  h3 {
    font-weight: bold;
    font-size: 26px;
    line-height: 30px;
    text-align: left;
    color: $secondary;
    margin: 0 auto 26px;
    width: 100%;
    max-width: 400px;
    @media (min-width: 768px) {
      max-width: initial;
      text-align: center;
      font-size: 36px;
      line-height: 42px;
      margin: 0 0 68px;
    }
  }
  .ProductsWrapper {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
    &>div {
      width: 100%;
    }

    @media (min-width: 768px) {
      max-width: 800px;
      margin: 0 auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      &>div {
        width: calc(50% - 12px);
      }
    }
  }
}